export const loadScript = async (src: string): Promise<Event> => (
  await new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.src = src
    script.async = true
    script.onload = resolve
    script.onerror = (e) => reject(new Error(`Failed to load script ${src}.`))
    document.body.appendChild(script)
  })
)

export const loadStyles = async (src: string): Promise<Event> => (
  await new Promise((resolve, reject) => {
    const link = document.createElement('link')
    link.href = src
    link.rel = 'stylesheet'
    link.type = 'text/css'
    link.onload = resolve
    link.onerror = (e) => reject(new Error(`Failed to load styles ${src}.`))
    document.head.appendChild(link)
  })
)
