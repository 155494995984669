import React from 'react'
import transformation from '../data/transformation-data.json'

const ListSection = (): React.ReactElement => (
    <section id="transformations" className="list-section">
        <h2 className="list-section__text">
          and significantly boost transformation programs
        </h2>
        <ul className="list-section__list">
          {transformation.map((transf) => (
            <li key={transf.text} className="list-section__item">{transf.text}</li>
          ))}
        </ul>
    </section>
)
export default ListSection
