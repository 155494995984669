import { StaticImage } from 'gatsby-plugin-image'
import React, { useState, useCallback } from 'react'
import QuoteVector from '../assets/quote'

interface ITeamItem {
  name: string
  title: string
  photo: string
  hashtag: string
  quote: string
  linkedin?: string
}

interface TeamItemProps {
  item: ITeamItem
}

const splitText = (text: string): string[] => text.split('\n')

const TeamItem = ({
  item: { name, title, photo, hashtag, quote, linkedin },
}: TeamItemProps): React.ReactElement => {
  const [isQuoting, setQuoting] = useState(false)
  const enableQuoting = useCallback(() => setQuoting(true), [setQuoting])
  const disableQuoting = useCallback(() => setQuoting(false), [setQuoting])

  const quoteLines = splitText(quote).map((line, i) => (
    <p key={i} className="team-item__quote-line">{line}</p>
  ))

  return (
    <div
      className="team-item"
      data-is-quoting={isQuoting || undefined}
    >

      <div className="team-item__text-content">
        <QuoteVector />

        <div className="team-item__quote team-item__quote--inline">
          {quoteLines}
        </div>
        <div className="team-item__quote-actions">
          <button
            type="button"
            onClick={enableQuoting}
            className="team-item__quote-toggle-btn"
          >
            Show more
          </button>
        </div>

        <div className="team-item__hash">{hashtag}</div>
      </div>

      <div className="team-item__avatar">
        <div className="team-item__img-wrapper">
          {photo === 'michael'
            ? <StaticImage
                src="../assets/team/michael-avatar.png"
                placeholder="none"
                className="team-item__img"
                alt=""
              />
            : photo === 'antonia'
              ? <StaticImage
                src="../assets/team/antonia-avatar.png"
                placeholder="none"
                className="team-item__img"
                alt=""
              />
              : photo === 'paul'
                ? <StaticImage
              src="../assets/team/paul-avatar.png"
              placeholder="none"
              className="team-item__img"
              alt=""
            />

                : photo === 'miriam'
                  ? <StaticImage
              src="../assets/team/miriam-avatar.png"
              placeholder="none"
              className="team-item__img"
              alt=""
            />
                  : ''
          }
        </div>
        <div className="team-item__profile-text">
          <p className="team-item__title">{name}</p>
          <p className="team-item__subtitle">{title}</p>
        </div>

        {linkedin !== undefined &&
          <a href={linkedin} title="zum Linkedin Profil" target="blank" className="team-item__link">
          </a>
        }
      </div>

      <div className="team-item__quote-modal">
        <div className="team-item__quote-modal-body">
          <div className="team-item__quote team-item__quote--full">
            {quoteLines}
            <div className="team-item__hash">{hashtag}</div>
          </div>
        </div>

        <div className="team-item__quote-modal-actions">
          <button
            type="button"
            onClick={disableQuoting}
            className="team-item__quote-toggle-btn"
          >
            Show less
          </button>
          <QuoteVector />
        </div>
      </div>

    </div>
  )
}
export default TeamItem
