import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

const Header = (): React.ReactElement => (
    <header className="header">
        <div className="header__logo">
          <Link to="/">
            <StaticImage
              src="../assets/logo-white.svg"
              placeholder="none"
              className="header__img"
              alt="qibri logo, a hummingbird"
            />
          </Link>
        </div>
    </header>
)

export default Header
