import React from 'react'
import CalendlyButton from './calendlyButton'
import Calltoaction from './calltoaction'

const Hero = (): React.ReactElement => (
  <section id="hero" className="hero">
    <div className="hero__title">
      <h1>
        The first digital assistant for all your company&apos;s{' '}
        <span className="hero__title--underlined">(best-) practices</span>
      </h1>
      <h2>Helping transformation projects drive impact and succeed</h2>
    </div>

    <Calltoaction
      Button={
        <CalendlyButton icon={false} text="Your potential check" darkmode={true} />
      }
      text="You’ll be talking with Michael, our Founder."
    />
  </section>
)

export default Hero
