import React from 'react'
import CalendlyButton from './calendlyButton'
import Calltoaction from './calltoaction'

const ContactSection = (): React.ReactElement => (
  <section id="contact" className="contact">
    <h2 className="contact__title">
      How can we benefit your ongoing or upcoming transformation?
        </h2>

    <p className="contact__subtitle">
      We are currently offering free potential check to setup a benchmarking
        </p>

    <Calltoaction
      Button={<CalendlyButton icon={true} text="Schedule a call" darkmode={false}/>}
      text="You’ll be talking with Michael, our Founder."
    />

  </section>
)

export default ContactSection
