import React, { useCallback, useEffect, useState } from 'react'
import calendlyAPI, { CalendlyAPI, getCalendlyURL } from '../utils/calendly'
import Button, { ButtonProps } from './button'
import calendlyConfig from '../data/calendly.json'

type CalendlyButtonProps = Omit<ButtonProps, 'onClick'>

const calendlyUrl = getCalendlyURL(
  calendlyConfig.account,
  calendlyConfig.type,
)

const CalendlyButton = (props: CalendlyButtonProps): React.ReactElement => {
  const [api, setAPI] = useState(null as CalendlyAPI|Error|null)

  useEffect(() => {
    void calendlyAPI().then(setAPI, setAPI)
  }, [])

  const handleClick = useCallback(() => {
    if (api && !((api instanceof Error))) {
      api.initPopupWidget({ url: calendlyUrl })
    }
  }, [api])

  if (!api) {
    return <Button {...props} state="loading" />
  }

  if (api instanceof Error) {
    return <Button {...props} state="error" text="Failed to load Calendly" />
  }

  return <Button {...props} onClick={handleClick} />
}

export default CalendlyButton
