import React from 'react'

interface IFeatureItem {
  icon: string
  title: string
  content: string
}

interface FeatureItemProps {
  item: IFeatureItem
}

const FeatureItem = ({ item: { icon, title, content } }: FeatureItemProps): React.ReactElement => (
  <div className="feature-item">
    <div className="feature-item__icon">
      <figure className="feature-item__icon-fig" data-icon={icon}></figure>
    </div>
    <h3 className="feature-item__title">{title}</h3>
    <hr className="feature-item__separator" aria-hidden="true"></hr>
    <p className="feature-item__content">{content}</p>
  </div>
)
export default FeatureItem
