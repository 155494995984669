import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

interface CalltoactionProps {
  Button: React.ReactElement
  text: string
}

const Calltoaction = ({ Button, text }: CalltoactionProps): React.ReactElement => (
  <div className="cta">
    {Button}

    <div className="cta__avatar">
      <StaticImage
        src="../assets/decorationArrow.svg"
        placeholder="none"
        className="cta__decoration"
        alt=""
        aria-hidden="true"
      />
      <div className="cta__img-wrapper">
        <StaticImage
          src="../assets/team/michael-avatar.png"
          placeholder="none"
          className="cta__img"
          alt="Michael von qibri"
        />
      </div>
      <p>{text}</p>
    </div>
  </div>
)

export default Calltoaction
