
import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const Mockups = (): React.ReactElement => (
  <section id="mockups" className="mockup">
     <StaticImage
      src="../assets/mockups/mockups.png"
      placeholder="none"
      className="mockup__img"
      alt="admin dashboard of the qibri desktop and mobile app"
    />
  </section>
)
export default Mockups
