import React from 'react'

interface IBenefitItem {
  number: string
  title: string
  icon: string
}

interface BenefitItemProps {
  item: IBenefitItem
}

const BenefitItem = ({ item: { number, title, icon } }: BenefitItemProps): React.ReactElement => (
  <div className="benefit-item">
    <div className="benefit-item__icon">
      <figure className="benefit-item__icon-fig" data-icon={icon}></figure>
    </div>
    <div className="benefit-item__text-content">
      <p className="benefit-item__number">{number}</p>
      <p className="benefit-item__title">{title}</p>
    </div>
  </div>
)
export default BenefitItem
