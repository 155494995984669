// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const naivelyMemoize = <F extends (...args: any[]) => ReturnType<F>>(
  fn: F,
): ((...args: Parameters<F>) => ReturnType<F>) => {
  let wasFired = false
  let result: ReturnType<F>|null = null
  return (...args: Parameters<F>) => {
    if (!wasFired) {
      result = fn(...args)
      wasFired = true
    }
    return result as ReturnType<F>
  }
}
