import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const Banner = (): React.ReactElement => (
  <section id="testimonials" className="banner">
    <p className="banner__text">
      Our approach was  developed and proven at
    </p>
    <div className="banner__logos">
      <StaticImage
        src="../assets/partner/siemens.svg"
        placeholder="tracedSVG"
        className="banner__img"
        alt="Siemens"
      />
      <StaticImage
        src="../assets/partner/omv.svg"
        placeholder="tracedSVG"
        className="banner__img"
        alt="OMV"
      />
      <StaticImage
        src="../assets/partner/avl.svg"
        placeholder="tracedSVG"
        className="banner__img"
        alt="AVL"
      />
    </div>
  </section>
)
export default Banner
