import React from 'react'
import teammember from '../data/team-data.json'
import TeamItem from './teamItem'

const TeamSection = (): React.ReactElement => (
  <section id="team" className="team">
    <h2 className="team__title">Meet the team</h2>

    <div className="team__items">
      {teammember.map((team) => (
        <TeamItem key={team.name} item={team} />
      ))}
    </div>
  </section>
)
export default TeamSection
