import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import ogImage from '../assets/og-image.png'

interface MetaItem {
  name: string
  content: string
}

interface SEOItem {
  title?: string
  description?: string
  url?: string
  keywords?: string[]
  meta?: MetaItem[]
  image?: string
}

interface SEOProps {
  item?: SEOItem
}

const SEO = ({ item }: SEOProps): React.ReactElement => {
  const { site: { siteMetadata } } = useStaticQuery(graphql`{
    site {
      siteMetadata {
        title
        description
        url
        keywords
        image
      }
    }
  }`)

  const siteTitle = item?.title ?? siteMetadata.title
  const siteDescription = item?.description ?? siteMetadata.description
  const siteUrl = item?.url ?? siteMetadata.url
  const siteImage = item?.image ?? ogImage
  const siteKeywords = [
    ...(siteMetadata.keywords ?? []),
    ...(item?.keywords ?? []),
  ].join(',')

  const metaData = [
    { name: 'canonical', content: siteUrl },
    { name: 'description', content: siteDescription },
    { name: 'image', content: siteImage },
    { name: 'og:url', content: siteUrl },
    { name: 'og:title', content: siteTitle },
    { name: 'og:description', content: siteDescription },
    { name: 'og:image', content: siteImage },
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:title', content: siteTitle },
    { name: 'twitter:description', content: siteDescription },
    { name: 'twitter:image', content: siteImage },
    { name: 'keywords', content: siteKeywords },
    ...(siteMetadata.meta ?? []),
  ]

  return (
    <Helmet
      htmlAttributes={{ lang: 'en' }}
      title={siteTitle}
      meta={metaData}
    />
  )
}

export default SEO
