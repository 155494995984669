import { loadScript, loadStyles } from './dom'
import { naivelyMemoize } from './memo'

export interface CalendlyAPI {
  initPopupWidget: (options: {
    url: string
  }) => void
  initBadgeWidget: (options: {
    url: string
    text: string
    color: string
    textColor: string
    branding: boolean
  }) => void
}

declare global {
  interface Window {
    Calendly: CalendlyAPI|undefined
  }
}

export const getCalendlyURL = (
  account: string,
  event: string,
): string => `https://calendly.com/${account}/${event}`

const calendlyAPI = (() => {
  const loadAPI = async () => {
    await Promise.all([
      loadStyles('https://assets.calendly.com/assets/external/widget.css'),
      loadScript('https://assets.calendly.com/assets/external/widget.js'),
    ])
    if (!window.Calendly) throw new Error('Failed to load Calendly')
    return window.Calendly
  }
  return naivelyMemoize(loadAPI)
})()

export default calendlyAPI
