import React from 'react'
import feats from '../data/feature-data.json'
import FeatureItem from './featureItem'

const FeatureSection = (): React.ReactElement => (
    <section id="features" className="features">
        <h2 className="features__title">Our magical ingredients</h2>
        {feats.map((feat) => (
          <FeatureItem key={feat.title} item={feat} />
        ))}
    </section>
)
export default FeatureSection
