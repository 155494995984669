import React from 'react'
import Header from '../components/header'
import Footer from '../components/footer'
import Hero from '../components/hero'
import FeatureSection from '../components/featureSection'
import ContactSection from '../components/contactSection'
import Banner from '../components/banner'
import ListSection from '../components/listSection'
import Mockups from '../components/mockupSection'
import BenefitSection from '../components/benefitSection'
import SEO from '../components/seo'
import TeamSection from '../components/teamSection'

const IndexPage = (): React.ReactElement => (
    <>
      <Header />
      <SEO/>

      <main className="main">
        <Hero />
        <Mockups/>
        <FeatureSection/>
        <BenefitSection/>
        <ListSection/>
        <Banner/>
        <TeamSection/>
        <ContactSection/>
      </main>

      <Footer />
    </>
)
export default IndexPage
