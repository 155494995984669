import React from 'react'

export interface ButtonProps {
  icon: boolean
  text: string
  darkmode: boolean
  state?: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

const Button = ({
  icon,
  text,
  darkmode,
  state,
  onClick,
}: ButtonProps): React.ReactElement => {
  const btnClasses = ['button']
  if (darkmode) btnClasses.push('button--darkmode')
  if (!onClick) btnClasses.push('button--mock')

  return (
    <button className={btnClasses.join(' ')} type="button" onClick={onClick} data-state={state} >
      {text}
      {icon && <span aria-hidden="true" data-icon-name="arrow-next"></span>}
    </button>
  )
}
export default Button
