import React from 'react'
import benefits from '../data/benefits-data.json'
import BenefitItem from './benefitItem'

const BenefitSection = (): React.ReactElement => (
    <section id="benefits" className="benefits">
        <div className="benefits__header">
          <h2 className="benefits__title">We profoundly enhance the productivity of companies</h2>
          <div className="benefits__claim">
            <span className="benefits__claim--bold">approx. 1 Mio € /  year</span>{' '}
            in productivity gains per 1000 employees
          </div>
        </div>

        <div className="benefits__items">
          {benefits.map((benef) => (
            <BenefitItem key={benef.title} item={benef} />
          ))}
        </div>
    </section>
)
export default BenefitSection
